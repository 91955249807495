.wrapper-wishlist {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .clash-card-wishlist {
    background: white;
    width: 100%;
    height: 140px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .clash-card__image-wishlist {
    position: relative;
    height: 100%;
    margin-right: 15px;
  }

  .clash-card__image--barbarian-wishlist {
    overflow: hidden;
  }

  .clash-card__image--barbarian-wishlist img {
    max-height:100%;
    width: auto;
    height: auto;
  }

  .clash-card__level-wishlist {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-wishlist {
    padding: 0 20px;
    font-size: 14px;
    height: 60px;
    overflow: hidden;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
  }

  .clash-card__unit-name-wishlist-s {
    padding: 0 20px;
    font-size: 14px;
    overflow: hidden;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description-wishlist {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats-wishlist {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-top: 10px;
    float: right;
    flex-grow: 2;
  }

  .clash-card__unit-stats-wishlist .one-third {
    float: right;
    padding: 20px 15px;
    display: flex;
  }

  .clash-card__unit-stats-wishlist .stat {
    position: relative;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-wishlist .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .addToWishList {
    background: white !important;
  }

  .addToWishList .plus {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .addToWishList .heart {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .scrolling-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .scrolling-wrapper-card {
    font-size: 80px;
    display: inline-block;
  }