.box {
    height: 30vh;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    overflow: scroll;
}

.box .boxSuggestions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 10px;
}

.dropdownBox {
    margin-top: 5px;
}

.inputRow {
    display: flex;
    flex-direction: row;
}

.iconDivHolder {
    display: flex;
    align-items: flex-end;
}

.inputRow .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
    height: 70%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 5px;
    justify-content: space-evenly;
    /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.253) */
}

.inputRow .icons span {
    font-weight: 700;
    margin-right: 5px;

}

.ABCholder {
    background: rgb(230, 228, 228);
    padding: 5px 7px 0;
}

.ABC {
    display: flex;
    justify-content: center;
    background: white;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.068);
}

.labelSearch {
    padding-left: 10px;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
}

.labelSearch i {
    margin-left: 5px;
}

.closeSug {
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    background-color: rgb(209, 39, 39);
}

.searchOverlay {
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.664);
    height: 100vh;
}

.closeSug i {
    padding: 14px 0;
    font-size: 30px;
}


.boxTitle {
    padding: 10px;
    color: rgb(92, 92, 92)
}