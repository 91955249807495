.wrapper-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .carousel .slide {
    background: white !important;
  }

  .carousel .control-dots {
    padding: 0 !important;
  }

  .clash-card-item {
    background: white;
    width: 100%;
    padding-bottom: 70px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .b2c-description-title {
    margin-top: 20px;
    text-align: left;
  }

  .b2c-description-holder {
    padding: 0 30px;
    text-align: left;
  }

  .b2c-description {
    font-size: 12px;
    text-align: left;
    font-weight: normal;
  }

  .clash-card__image-item {
    position: relative;
    margin-bottom: 5px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian-item {
    overflow: hidden;
  }

  .units {
    font-size: 10px;
    color: grey;
    padding-bottom: 20px;
    font-weight: lighter;
  }

  .imageHolder {
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 img {
    max-height:100%;
    /* width: auto; */
    /* height: auto; */
  }

  .clash-card__level-item {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-item {
    padding: 0 20px 20px;
    font-size: 14px;
    /* height: 60px; */
    overflow: hidden;
    color: rgb(51, 50, 50);
    font-weight: 800;
  }

  .clash-card__unit-name-item-s {
    padding: 0 20px;
    font-size: 14px;
    overflow: hidden;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description-item {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats-item {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .eWrapper {
    display: flex;
    justify-content: center;
  }

  .clash-card__unit-stats-item .price {
    float: left;
    height: 100px;
    display: flex;
    align-items: center;
    font-weight: 100;
  }

  .clash-card__unit-stats-item .extra {
    float: left;
    height: 100px;
    display: flex;
    align-items: center;
    /* min-width: 200px; */
  }

  .black {
    background: rgba(0, 0, 0, 0.835);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info {
    background: rgb(233, 232, 232);
  }

  .price .clash-card__unit-description-item {
    font-size: 20px;
  }

  .fav-icon-holder {
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .fav-icon-holder i {
    padding: 5px;
    font-size: 30px;
  }
  .clash-card__unit-stats-item .one-third {
    width: 100px;
    float: left;
    height: 100px;
  }

  .stat {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-item .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .addToWishList {
    background: white !important;
  }

  .addToWishList .plus {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .addToWishList .heart {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .scrolling-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 50px 0 20px 0;
  }
  .scrolling-wrapper-card {
    font-size: 80px;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.102);
    border-radius: 10px;
    padding: 20px;
    margin: 0 20px;
  }