.cardHolder {
    min-height: 90px;
    padding: 10px;
    border: none;
    width: 50%;
}

.card {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background-color: white;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 2px rgba(31, 31, 31, 0.034);
}

.left{
    display: flex;
    flex-direction: column;
}

.type {
    color: black;
    font-weight: 700;
}

.right {
    display: flex;
    flex-direction: row;
}

.right .extra {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.right img {
    max-height: 70px;
}

.colorBox {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.noSuggestions {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    font-weight: 500;
    font-size: 20px;
}