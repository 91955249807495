.general {
    font-family: 'Open Sans',sans-serif;
}

.withPage {
    margin-top: 230px;
}

.withPageItem {
    margin-top: 180px;
}

.hr {
    width: 100px;
}

.backgroundWhite {
    background-color: white;
}

.lightBoxShadow {
    box-shadow: 0px 0px 5px 2px rgba(31, 31, 31, 0.171);
}

* {
    outline: none !important;
}

.count {
    color: grey;
}