
.col-pg {
    padding: 0px;
}

.wrapper-pg {
    padding-bottom: 60px;
  }

.clash-card-pg {
    background: white;
    width: 300px;
    height: 350px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.247);
    border-radius: 14px;
  }

  .clash-card__image-pg {
    position: relative;
    max-height: 280px;
    width: 100%;
    margin-bottom: 25px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian-pg {
    overflow: hidden;
  }

  .clash-card__image--barbarian-pg img {
    width: 100%;
    height: 100%;
  }

  .clash-card__level-pg {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-pg {
    padding: 0 20px;
    font-size: 20px;
    height: 100px;
    overflow: hidden;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
  }

  .clash-card__unit-description-pg {
    padding: 0 20px;
  }

  .clash-card__unit-stats-pg {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .clash-card__unit-stats-pg .one-third {
    width: 100%;
    float: left;
    padding: 20px 15px;
  }

  .clash-card__unit-stats-pg .stat {
    position: relative;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-pg .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }