
.headerContainer .top {
    background: white;
}

.topContainer {
    margin: 0 15px;
}

.headerContainer .logo img, .headerContainer .designedWithPassionLogo img{
    height: 60px;
	margin-top: 20px;
}

.fab {
    width: 100%;
}

.searchbar {
    background-color: white;
    padding-top: 10px;

}

input,.MuiFormLabel-root {
    color: rgb(51, 51, 51) !important;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
}

.MuiTextField-inputRoot {
    background-color: red !important;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid darkred !important;

}

.homeFab {
    background-color: white !important;
    margin-top: 20px !important;
}

.homeFab i {
    font-size: 20px;
}

.infoFab {
    background-color: white !important;
    margin: 20px 0 0 15px!important;
}

.infoFab i {
    font-size: 20px;
}

.keyboard {
    width: 100%;
    position: fixed;
    top: 40vh;
}

.feedbackModal {
    padding: 10px 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 300px;
    background: white;
    flex-direction: column;
    display: flex;
}

.feedbackModalTitle {
    font-weight: bold;
    font-size: 20px;
}

.feedbackModalBody {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.settingsModal {
    padding: 10px 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 300px;
    background: white;
    flex-direction: column;
    display: flex;
}

.settingsModalTitle {
    font-weight: bold;
    font-size: 20px;
}

.settingsModalBody {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}