
.wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .clash-card {
    background: white;
    width: 200px;
    height: 400px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .clash-card__image {
    position: relative;
    height: 200px;
    margin-bottom: 5px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian {
    overflow: hidden;
  }

  .clash-card__image--barbarian img {
    width: 100%;
  }

  .clash-card__level {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name {
    padding: 0 20px;
    font-size: 14px;
    display: block;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .clash-card__unit-stats .one-third {
    width: 100%;
    float: left;
    padding: 20px 15px;
  }

  .clash-card__unit-stats .stat {
    position: relative;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }