
.col-pg {
    padding: 0px;
}

.wrapper-pg {
    padding-bottom: 60px;
  }

.clash-card-pg {
    background: white;
    width: 300px;
    height: 350px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.247);
    border-radius: 14px;
  }

  .clash-card__image-pg {
    position: relative;
    max-height: 280px;
    width: 100%;
    margin-bottom: 25px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian-pg {
    overflow: hidden;
  }

  .clash-card__image--barbarian-pg img {
    width: 100%;
    height: 100%;
  }

  .clash-card__level-pg {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-pg {
    padding: 0 20px;
    font-size: 20px;
    height: 100px;
    overflow: hidden;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
  }

  .clash-card__unit-description-pg {
    padding: 0 20px;
  }

  .clash-card__unit-stats-pg {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .clash-card__unit-stats-pg .one-third {
    width: 100%;
    float: left;
    padding: 20px 15px;
  }

  .clash-card__unit-stats-pg .stat {
    position: relative;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-pg .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }
.general {
    font-family: 'Open Sans',sans-serif;
}

.withPage {
    margin-top: 230px;
}

.withPageItem {
    margin-top: 180px;
}

.hr {
    width: 100px;
}

.backgroundWhite {
    background-color: white;
}

.lightBoxShadow {
    box-shadow: 0px 0px 5px 2px rgba(31, 31, 31, 0.171);
}

* {
    outline: none !important;
}

.count {
    color: grey;
}

.headerContainer .top {
    background: white;
}

.topContainer {
    margin: 0 15px;
}

.headerContainer .logo img, .headerContainer .designedWithPassionLogo img{
    height: 60px;
	margin-top: 20px;
}

.fab {
    width: 100%;
}

.searchbar {
    background-color: white;
    padding-top: 10px;

}

input,.MuiFormLabel-root {
    color: rgb(51, 51, 51) !important;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
}

.MuiTextField-inputRoot {
    background-color: red !important;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid darkred !important;

}

.homeFab {
    background-color: white !important;
    margin-top: 20px !important;
}

.homeFab i {
    font-size: 20px;
}

.infoFab {
    background-color: white !important;
    margin: 20px 0 0 15px!important;
}

.infoFab i {
    font-size: 20px;
}

.keyboard {
    width: 100%;
    position: fixed;
    top: 40vh;
}

.feedbackModal {
    padding: 10px 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 70%;
    height: 300px;
    background: white;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

.feedbackModalTitle {
    font-weight: bold;
    font-size: 20px;
}

.feedbackModalBody {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.settingsModal {
    padding: 10px 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 70%;
    height: 300px;
    background: white;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

.settingsModalTitle {
    font-weight: bold;
    font-size: 20px;
}

.settingsModalBody {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}
.pagination {
    border: none;
    margin: auto;
    box-shadow: 0px 5px 7px 2px rgba(0, 0, 0, 0.185);
}
.page-link {
    border: none;
    font-size: 20px;
    font-weight: 1000;
    color: rgb(110, 110, 110);
    background-color: rgb(255, 255, 255);
}
.page-item {
    border: none;
}
.page-item.active .page-link{
    border-radius: 10%;
    background-color: rgb(39, 39, 39);
}
.page-link:focus {
    border-color: inherit;
    box-shadow: none;
}
#paginationPrev, #paginationNext, #paginationFirst, #paginationLast {
    background: rgb(235, 234, 234);
}
.fabNav {
    background: none !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
    font-size: 10px !important;
    box-shadow: none !important;
}

.fabBack {
    background: white !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.backIcon {
    padding-top: 2px;
    font-size: 20px;
}

.fabWishlist {
    background: white !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
}

.fabCheckout {
    background: darkred !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
}

.fabWishlist {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.fabWishlist .fa-shopping-cart {
    color: darkred;
    font-size: 15px;
}

.fabWishlist .counter {
    color: darkred;
    font-size: 14px;
    margin-left: 10px;
}

.sortTrash {
    background: darkred !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.filterActive {
    background: #f09902 !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.filterInactive {
    color: #f09902 !important;
    background: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}
.cardHolder {
    min-height: 90px;
    padding: 10px;
    border: none;
    width: 50%;
}

.card {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background-color: white;
    border: none;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-shadow: 0px 0px 5px 2px rgba(31, 31, 31, 0.034);
}

.left{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.type {
    color: black;
    font-weight: 700;
}

.right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.right .extra {
    margin-right: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.right img {
    max-height: 70px;
}

.colorBox {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.noSuggestions {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.text {
    font-weight: 500;
    font-size: 20px;
}
.box {
    height: 30vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 10px;
    overflow: scroll;
}

.box .boxSuggestions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 10px;
}

.dropdownBox {
    margin-top: 5px;
}

.inputRow {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.iconDivHolder {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.inputRow .icons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    width: 120px;
    height: 70%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 5px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.253) */
}

.inputRow .icons span {
    font-weight: 700;
    margin-right: 5px;

}

.ABCholder {
    background: rgb(230, 228, 228);
    padding: 5px 7px 0;
}

.ABC {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background: white;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.068);
}

.labelSearch {
    padding-left: 10px;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
}

.labelSearch i {
    margin-left: 5px;
}

.closeSug {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
    background-color: rgb(209, 39, 39);
}

.searchOverlay {
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.664);
    height: 100vh;
}

.closeSug i {
    padding: 14px 0;
    font-size: 30px;
}


.boxTitle {
    padding: 10px;
    color: rgb(92, 92, 92)
}

.wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .clash-card {
    background: white;
    width: 200px;
    height: 400px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .clash-card__image {
    position: relative;
    height: 200px;
    margin-bottom: 5px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian {
    overflow: hidden;
  }

  .clash-card__image--barbarian img {
    width: 100%;
  }

  .clash-card__level {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name {
    padding: 0 20px;
    font-size: 14px;
    display: block;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .clash-card__unit-stats .one-third {
    width: 100%;
    float: left;
    padding: 20px 15px;
  }

  .clash-card__unit-stats .stat {
    position: relative;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }
.wrapper-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .carousel .slide {
    background: white !important;
  }

  .carousel .control-dots {
    padding: 0 !important;
  }

  .clash-card-item {
    background: white;
    width: 100%;
    padding-bottom: 70px;
    overflow: hidden;
    display: inline-block;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .b2c-description-title {
    margin-top: 20px;
    text-align: left;
  }

  .b2c-description-holder {
    padding: 0 30px;
    text-align: left;
  }

  .b2c-description {
    font-size: 12px;
    text-align: left;
    font-weight: normal;
  }

  .clash-card__image-item {
    position: relative;
    margin-bottom: 5px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .clash-card__image--barbarian-item {
    overflow: hidden;
  }

  .units {
    font-size: 10px;
    color: grey;
    padding-bottom: 20px;
    font-weight: lighter;
  }

  .imageHolder {
    height: 330px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

 img {
    max-height:100%;
    /* width: auto; */
    /* height: auto; */
  }

  .clash-card__level-item {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-item {
    padding: 0 20px 20px;
    font-size: 14px;
    /* height: 60px; */
    overflow: hidden;
    color: rgb(51, 50, 50);
    font-weight: 800;
  }

  .clash-card__unit-name-item-s {
    padding: 0 20px;
    font-size: 14px;
    overflow: hidden;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description-item {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats-item {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .eWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .clash-card__unit-stats-item .price {
    float: left;
    height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 100;
  }

  .clash-card__unit-stats-item .extra {
    float: left;
    height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /* min-width: 200px; */
  }

  .black {
    background: rgba(0, 0, 0, 0.835);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .info {
    background: rgb(233, 232, 232);
  }

  .price .clash-card__unit-description-item {
    font-size: 20px;
  }

  .fav-icon-holder {
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
  }
  .fav-icon-holder i {
    padding: 5px;
    font-size: 30px;
  }
  .clash-card__unit-stats-item .one-third {
    width: 100px;
    float: left;
    height: 100px;
  }

  .stat {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-item .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .addToWishList {
    background: white !important;
  }

  .addToWishList .plus {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .addToWishList .heart {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .scrolling-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 50px 0 20px 0;
  }
  .scrolling-wrapper-card {
    font-size: 80px;
    display: -webkit-flex;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.102);
    border-radius: 10px;
    padding: 20px;
    margin: 0 20px;
  }
.wrapper-wishlist {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .clash-card-wishlist {
    background: white;
    width: 100%;
    height: 140px;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    border-radius: 10 + 5;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, 0.199);
    border-radius: 14px;
  }

  .clash-card__image-wishlist {
    position: relative;
    height: 100%;
    margin-right: 15px;
  }

  .clash-card__image--barbarian-wishlist {
    overflow: hidden;
  }

  .clash-card__image--barbarian-wishlist img {
    max-height:100%;
    width: auto;
    height: auto;
  }

  .clash-card__level-wishlist {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .clash-card__unit-name-wishlist {
    padding: 0 20px;
    font-size: 14px;
    height: 60px;
    overflow: hidden;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
  }

  .clash-card__unit-name-wishlist-s {
    padding: 0 20px;
    font-size: 14px;
    overflow: hidden;
    color: black;
    font-weight: 900;
  }

  .clash-card__unit-description-wishlist {
    padding: 0 20px;
    font-size: 12px;
  }

  .clash-card__unit-stats-wishlist {
    font-weight: 700;
    padding: 0 25px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-top: 10px;
    float: right;
    -webkit-flex-grow: 2;
            flex-grow: 2;
  }

  .clash-card__unit-stats-wishlist .one-third {
    float: right;
    padding: 20px 15px;
    display: -webkit-flex;
    display: flex;
  }

  .clash-card__unit-stats-wishlist .stat {
    position: relative;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .clash-card__unit-stats-wishlist .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .addToWishList {
    background: white !important;
  }

  .addToWishList .plus {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .addToWishList .heart {
    color: darkred;
    margin: 6px;
    font-size: 25px;
  }

  .scrolling-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .scrolling-wrapper-card {
    font-size: 80px;
    display: inline-block;
  }
.summary {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.summaryImage {
    width: 100px;
    margin-top: 10px;
}
