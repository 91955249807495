.pagination {
    border: none;
    margin: auto;
    box-shadow: 0px 5px 7px 2px rgba(0, 0, 0, 0.185);
}
.page-link {
    border: none;
    font-size: 20px;
    font-weight: 1000;
    color: rgb(110, 110, 110);
    background-color: rgb(255, 255, 255);
}
.page-item {
    border: none;
}
.page-item.active .page-link{
    border-radius: 10%;
    background-color: rgb(39, 39, 39);
}
.page-link:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#paginationPrev, #paginationNext, #paginationFirst, #paginationLast {
    background: rgb(235, 234, 234);
}