.fabNav {
    background: none !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
    font-size: 10px !important;
    box-shadow: none !important;
}

.fabBack {
    background: white !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.backIcon {
    padding-top: 2px;
    font-size: 20px;
}

.fabWishlist {
    background: white !important;
    color: grey !important;
    margin: 10px 10px 0 0 !important;
}

.fabCheckout {
    background: darkred !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
}

.fabWishlist {
    display: flex;
    align-items: center;
}

.fabWishlist .fa-shopping-cart {
    color: darkred;
    font-size: 15px;
}

.fabWishlist .counter {
    color: darkred;
    font-size: 14px;
    margin-left: 10px;
}

.sortTrash {
    background: darkred !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.filterActive {
    background: #f09902 !important;
    color: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.filterInactive {
    color: #f09902 !important;
    background: white !important;
    margin: 10px 10px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}